import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import whitepaper from 'src/assets/images/file-list.svg';
import style from './Whitepaper.module.scss';

const Whitepaper = () => {
  const { t, i18n } = useTranslation();
  return (
    <Link
      to={`${
        i18n.language === 'ru' ? `/${i18n.language}/whitepaper` : `/whitepaper`
      }`}
      className={style.whitepaper}
    >
      <img src={whitepaper} alt="whitepaper" />
      <p>{t('whitepaper')}</p>
    </Link>
  );
};

export default Whitepaper;
