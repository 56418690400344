import { FC } from 'react';
import style from './SecondaryLine.module.scss';

type PropsType = {
  left?: string;
  center?: string;
};

const SecondaryLine: FC<PropsType> = ({ center = '', left = '' }) => {
  return (
    <>
      <div className={style.left}>
        {left && <div className={style.lineText}>{left}</div>}
      </div>
      <div className={style.center}>
        {center && <div className={style.lineText}>{center}</div>}
      </div>
      <div className={style.right} />
    </>
  );
};

export default SecondaryLine;
