import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tableRate } from 'src/helpers/static-data';
import style from './TableRate.module.scss';

const TableRate: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={style.containerRate}>
      <div className={style.header}>
        <div className={style.summary}>{t('tableRate.amount')}</div>
        <div className={style.rate}>{t('tableRate.bid')}</div>
      </div>
      {tableRate.map(({ min, max, rate }) => (
        <div className={style.item} key={`${max}_${rate}`}>
          <div className={style.summary}>
            {!!min && (
              <>
                {t('tableRate.from')} <span>{min} 000</span> {t('tableRate.to')}
              </>
            )}{' '}
            {!min && `${t('tableRate.over')} `}
            <span>{max} 000</span> X11
          </div>
          <div className={style.rate}>{rate}%</div>
        </div>
      ))}
    </div>
  );
};
export default TableRate;
