import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import { PrismicProvider } from '@prismicio/react';
import App from './App';
import WhitepaperPage from './components/WhitepaperPage/WhitepaperPage';

import { client } from './prismic';
import PDFPage from './components/PDFPage/PDFPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <PrismicProvider client={client}>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/whitepaper" element={<WhitepaperPage />} />
        <Route path="/pdf/:document" element={<PDFPage />} />
        <Route path="/:locale">
          <Route index element={<App />} />
          <Route path="/:locale/whitepaper" element={<WhitepaperPage />} />
        </Route>
      </Routes>
    </PrismicProvider>
  </BrowserRouter>,
);
