import { FC } from 'react';
import { financeIcons, socialIcons } from 'src/helpers/static-data';
import logo from 'src/assets/images/footer/Logo_X11_bank.svg';
import chat from 'src/assets/images/footer/wechat-line.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './Footer.module.scss';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import SocialIcons from '../SocialIcons/SocialIcons';

const Footer: FC = () => {
  const { i18n } = useTranslation();
  return (
    <footer className={style.container}>
      <SecondaryLine />
      <div className={style.footer}>
        <div className={style.logo}>
          <img src={logo} alt="logo" />
        </div>
        <div className={style.linkInfo}>
          <Link to="/pdf/general-terms" className={style.linkInfo__item}>
            <span>General Terms</span>
          </Link>
          <Link to="/pdf/privacy-policy" className={style.linkInfo__item}>
            <span>Privacy Policy</span>
          </Link>
          <Link
            to={`${
              i18n.language === 'ru'
                ? `/${i18n.language}/whitepaper`
                : `/whitepaper`
            }`}
            className={style.linkInfo__item}
          >
            <span>Whitepaper</span>
          </Link>
          <Link to="/pdf/token-purchase" className={style.linkInfo__item}>
            <span>Token Purchase Agreement</span>
          </Link>
          <Link to="/pdf/website-terms" className={style.linkInfo__item}>
            <span>Website Terms</span>
          </Link>
          <div className={`${style.linkInfo__item} ${style.item}`}>
            Smart-contract
            <a href="https://etherscan.io/token/0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a">
              <span>0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a</span>
            </a>
          </div>
          <div className={style.linkInfo__item}>
            <a href="mailto:x@bank11.io">x@bank11.io</a>
          </div>
          <div className={style.linkInfo__item}>
            <a href="https://t.me/x11fund" target="_blank" rel="noreferrer">
              <span>Support</span>
              <img src={chat} alt="chat" />
            </a>
          </div>
        </div>
        <div className={style.social}>
          <SocialIcons icons={socialIcons} width="144px" />
          <SocialIcons icons={financeIcons} width="219px" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
