import { FC } from 'react';
import { IconType } from 'src/helpers/static-data';
import style from './SocialIcons.module.scss';

type PropsType = {
  icons: IconType[];
  width: string;
};

const SocialIcons: FC<PropsType> = ({ icons, width }) => {
  return (
    <div className={style.container} style={{ width }}>
      {icons.map(({ icon, title, link }) =>
        link ? (
          <a key={title} href={link} target="_blank" rel="noreferrer">
            <img src={icon} alt={title} key={title} />
          </a>
        ) : (
          <img src={icon} alt={title} key={title} />
        ),
      )}
    </div>
  );
};

export default SocialIcons;
