import logo from 'src/assets/images/logo_gray.svg';
import check from 'src/assets/images/check.svg';
import check_2 from 'src/assets/images/check_2.svg';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import HeaderBlock from '../HeaderBlock/HeaderBlock';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './BenefitBlock.module.scss';

type Benefit = Array<
  string | { first: string; second?: string; footnote?: string }
>;
type PropsType = {
  items: Benefit;
  type: string;
};

const Benefititem: FC<PropsType> = ({ items, type }) => {
  return (
    <>
      {items.map((el) => (
        <div
          key={typeof el === 'string' ? el : el.first}
          className={style.benefit__item}
        >
          <div
            className={style.check}
            style={typeof el !== 'string' ? { alignSelf: 'flex-start' } : {}}
          >
            <div className={style.checkBg}>
              {type === 'x11' ? (
                <img src={check} alt="check" />
              ) : (
                <img src={check_2} alt="check_2" />
              )}
            </div>
          </div>
          {typeof el === 'string' ? (
            <p>{el}</p>
          ) : (
            <div>
              <p>
                {el.first}{' '}
                <span className={style.footnote}>{el?.footnote}</span>
              </p>
              {el?.second && (
                <>
                  <br />
                  <p>{el.second}</p>
                </>
              )}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const BenefitBlock = () => {
  const { t } = useTranslation();
  const benefitX11: Benefit = [
    t('benifits.benefitX11.item1'),
    t('benifits.benefitX11.item2'),
    t('benifits.benefitX11.item3'),
    t('benifits.benefitX11.item4'),
    t('benifits.benefitX11.item5'),
    t('benifits.benefitX11.item6'),
    t('benifits.benefitX11.item7'),
    t('benifits.benefitX11.item8'),
    t('benifits.benefitX11.item9'),
    t('benifits.benefitX11.item10'),
  ];

  const benefitBank: Benefit = [
    t('benifits.benefitBank.item1'),
    {
      first: t('benifits.benefitBank.item2.point1'),
      footnote: t('benifits.benefitBank.item2.point2'),
    },
    t('benifits.benefitBank.item3'),
    t('benifits.benefitBank.item4'),
    t('benifits.benefitBank.item5'),
    t('benifits.benefitBank.item6'),
    t('benifits.benefitBank.item7'),
    t('benifits.benefitBank.item8'),
    t('benifits.benefitBank.item9'),
    t('benifits.benefitBank.item10'),
  ];

  return (
    <div className={style.container}>
      <SecondaryLine />
      <div className={style.secondText}>{t('benifits.secondaryheader')}</div>
      <HeaderBlock title={t('benifits.header')} />
      <div className={style.wrapper}>
        <div className={style.bgText}>{t('benifits.bgText')}</div>
        <div className={style.benefitBlock}>
          <div className={style.line} />
          <div className={style.background} />
          <div className={style.benefit}>
            <img className={style.logo} src={logo} alt="logo_gray" />
            <Benefititem items={benefitX11} type="x11" />
          </div>
          <div className={`${style.benefit} ${style.second}`}>
            <p className={style.header}>{t('benifits.benefitBank.header')}</p>
            <Benefititem items={benefitBank} type="classic" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitBlock;
