import { useTranslation } from 'react-i18next';
import TableRate from '../TableRate/TableRate';
import style from './WorksBlock.module.scss';
import Faunder from '../FaunderBlock/Faunder/Faunder';

const WorksBlock = () => {
  const { t } = useTranslation();
  const points = [t('howItWorks.item1'), t('howItWorks.item2')];
  return (
    <div className={style.worksBlock}>
      <div className={style.works}>
        <h2 className={style.worksHeader}>{t('howItWorks.header')}</h2>
        <div className={style.aboutWork}>
          {points.map((el, i) => (
            <div key={el} className={style.point}>
              <div className={style.indexBlock}>
                <div className={style.indexPoint}>{i + 1}</div>
              </div>
              <p>{el}</p>
            </div>
          ))}
        </div>
        <TableRate />
      </div>
      <div>
        <Faunder idFaunder="ecosystem" />
      </div>
    </div>
  );
};

export default WorksBlock;
