import { FC, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import down from 'src/assets/images/faq/arrow-down.svg';
import up from 'src/assets/images/faq/arrow-up.svg';
import {
  faqReducer,
  QuestionType,
  setIsHidden,
  setState,
} from 'src/reducers/faqReducer';
import HeaderBlock from '../HeaderBlock/HeaderBlock';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './Faq.module.scss';

type PropsType = {
  items: QuestionType[];
  handleClick: (id: number, hidden: boolean) => void;
};

const FaqItem: FC<PropsType> = ({ items, handleClick }) => {
  return (
    <>
      {items.map(({ id, title, description, hidden, list }) => (
        <div
          className={hidden ? style.questionBlock : style.questionBlockOpen}
          key={id}
          onClick={() => handleClick(id, !hidden)}
          aria-hidden="true"
          style={{ background: hidden ? '' : '#E9EBF2' }}
        >
          <div className={style.questionItem}>
            <div className={style.questionPoint}>{id}</div>
            <div className={style.title}>{title}</div>
            <img className={style.arrow} src={hidden ? down : up} alt="arrow" />
          </div>
          {!hidden && (
            <div className={style.description}>
              {description &&
                (typeof description === 'string'
                  ? description
                  : description.map((item: string) => (
                      <p key={item}>{item}</p>
                    )))}
              {list && <ol>{list?.map((el) => <li key={el}>{el}</li>)}</ol>}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const Faq = () => {
  const { t, i18n } = useTranslation();
  const questions = [
    {
      id: 1,
      title: t('faq.questions.question1.title'),
      list: [
        t('faq.questions.question1.description.item1'),
        t('faq.questions.question1.description.item2'),
        t('faq.questions.question1.description.item3'),
        t('faq.questions.question1.description.item4'),
        t('faq.questions.question1.description.item5'),
        t('faq.questions.question1.description.item6'),
      ],
      hidden: true,
    },
    {
      id: 2,
      title: t('faq.questions.question2.title'),
      description: t('faq.questions.question2.description'),
      hidden: true,
    },
    {
      id: 3,
      title: t('faq.questions.question3.title'),
      description: t('faq.questions.question3.description'),
      hidden: true,
    },
    {
      id: 4,
      title: t('faq.questions.question4.title'),
      description: [
        t('faq.questions.question4.description1'),
        t('faq.questions.question4.description2'),
      ],
      hidden: true,
    },
    {
      id: 5,
      title: t('faq.questions.question5.title'),
      list: [
        t('faq.questions.question5.description.item1'),
        t('faq.questions.question5.description.item2'),
        t('faq.questions.question5.description.item3'),
        t('faq.questions.question5.description.item4'),
        t('faq.questions.question5.description.item5'),
        t('faq.questions.question5.description.item6'),
      ],
      hidden: true,
    },
    {
      id: 6,
      title: t('faq.questions.question6.title'),
      description: t('faq.questions.question6.description'),
      hidden: true,
    },
    {
      id: 7,
      title: t('faq.questions.question7.title'),
      description: [
        t('faq.questions.question7.description1'),
        t('faq.questions.question7.description2'),
        t('faq.questions.question7.description3'),
      ],
      hidden: true,
    },
    {
      id: 8,
      title: t('faq.questions.question8.title'),
      description: t('faq.questions.question8.description'),
      hidden: true,
    },
  ];
  const [state, dispatch] = useReducer(faqReducer, questions);

  const handleClick = (id: number, hidden: boolean) => {
    dispatch(setIsHidden(id, hidden));
  };

  useEffect(() => {
    dispatch(setState(questions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className={style.container}>
      <SecondaryLine />
      <div className={style.faqBlock}>
        <HeaderBlock title={t('faq.header')} />
        <div className={style.questions}>
          <div className={style.questionsBlock}>
            <FaqItem items={state.slice(0, 4)} handleClick={handleClick} />
          </div>
          <div className={style.questionsBlock}>
            <FaqItem items={state.slice(4)} handleClick={handleClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
