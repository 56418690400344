import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import auction from 'src/assets/images/legal_aspect/auction-line.svg';
import password from 'src/assets/images/legal_aspect/lock-password-line.svg';
import { checkItems } from 'src/helpers/static-data';
import HeaderBlock from '../HeaderBlock/HeaderBlock';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './LegalAspect.module.scss';

const LegalAspect: FC = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isIntersecting, setIntersecting] = useState(false);
  const isBrowser = typeof window !== 'undefined';
  const textBg = [style.bgText];
  const checkBlock = [style.checkBlock];
  let observer: IntersectionObserver;
  if (isBrowser) {
    observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
  }

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isIntersecting) {
    textBg.push(style.textAminate);
    checkBlock.push(style.checkBlockAnimate);
  }
  const legalAspects = [
    { icon: auction, title: t('legalAspects.item1') },
    { icon: password, title: t('legalAspects.item2') },
  ];
  return (
    <div className={style.container} ref={ref}>
      <SecondaryLine />
      <div className={textBg.join(' ')}>{t('legalAspects.reliability')}</div>
      <div className={style.legalAspect}>
        <HeaderBlock title={t('legalAspects.header')} />
        <div className={style.legalItems}>
          {legalAspects.map((el) => (
            <div key={el.title} className={style.item}>
              <img src={el.icon} alt={el.title} />
              <p>{el.title}</p>
            </div>
          ))}
        </div>
        <div className={checkBlock.join(' ')}>
          <p className={style.checkHeader}>{t('legalAspects.check')}</p>
          <div className={style.checkItems}>
            {checkItems.map(({ icon, title, link }) => (
              <div className={style.check} key={title}>
                <div className={style.checkImage}>
                  <img src={icon} alt={title} />
                </div>
                <a href={link}>
                  <p>{title}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalAspect;
