import { IconsFaunder } from 'src/helpers/static-data';
import logo from 'src/assets/images/logo.png';
import style from './FaunderBlock.module.scss';
import Faunder from './Faunder/Faunder';

const FaunderBlock = () => {
  return (
    <div className={style.faunderBlock}>
      <Faunder idFaunder="preview" />
      <div className={style.icons}>
        {IconsFaunder.map(({ icon, title }) => (
          <div className={style.icons__item} key={title}>
            <img src={icon} alt={title} />
          </div>
        ))}
      </div>
      <img className={style.logo_x11} src={logo} alt="logo_x11" />
    </div>
  );
};

export default FaunderBlock;
