/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// import faunder from 'src/assets/images/faunder.png';
import play from 'src/assets/images/play.svg';
import { FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import style from './Faunder.module.scss';

type PropsType = {
  idFaunder: string;
};

const Faunder: FC<PropsType> = ({ idFaunder }) => {
  const [isPlay, setIsPlay] = useState(false);
  const [player, setPlayer] = useState(null);
  const { i18n } = useTranslation();
  const videoRef = useRef();

  let videoSource;

  if (idFaunder === 'preview') {
    if (i18n.language === 'en') {
      videoSource = '/B11.mp4';
      // console.log('lang: ', i18n.language);
    } else {
      videoSource = '/B11.mp4';
    }
  } else {
    videoSource = '/bank11_mobile.mp4';
  }

  // console.log('lang: ', i18n.language);

  // const goFullscreen = () => {
  //   if (videoRef.current.mozRequestFullScreen) {
  //     videoRef.current.mozRequestFullScreen();
  //   } else if (videoRef.current.webkitRequestFullScreen) {
  //     videoRef.current.webkitRequestFullScreen();
  //   }
  // };

  /* videoElement.addEventListener('pause', (event) => {
    document.exitFullscreen();
  }); */

  const playVideo = () => {
    if (player?.play() !== undefined) {
      player
        ?.play()
        .then(() => {
          // Automatic playback started!
        })
        .catch((error) => {
          console.log(error);
          // Automatic playback failed.
          // Show a UI element to let the user manually start playback.
        });
    }
  };

  const onClickPlay = () => {
    playVideo();
    // if (idFaunder === 'preview') goFullscreen();
    setIsPlay(true);
  };

  // const onClickStop = () => {
  //   if (player?.paused) {
  //     playVideo();
  //     setIsPlay(true);
  //   } else {
  //     player?.pause();
  //     setIsPlay(false);
  //   }
  // };

  useEffect(() => {
    (async () => {
      const videoPlayer = await document.getElementById(`${idFaunder}`);
      setPlayer(videoPlayer);
      // videoPlayer.addEventListener('pause', () => {
      //   document.exitFullscreen();
      // });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.faunder}>
      {!isPlay && (
        <div className={style.play} onClick={onClickPlay} aria-hidden="true">
          <img src={play} alt="play" />
        </div>
      )}
      <video
        muted
        loop
        src={videoSource}
        id={idFaunder}
        // onClick={onClickStop}
        aria-hidden="true"
        ref={videoRef}
      >
        <track kind="captions" />
      </video>
      {/* <div className={style.faunderName}>X11 Founder Pavel Andreev</div> */}
    </div>
  );
};

export default Faunder;
