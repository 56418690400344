import { FC } from 'react';
import style from './HeaderBlock.module.scss';

type PropsType = {
  title: string;
};

const HeaderBlock: FC<PropsType> = ({ title }) => {
  return (
    <div className={style.headerBlock}>
      <p>{title}</p>
    </div>
  );
};

export default HeaderBlock;
