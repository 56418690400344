import { useEffect, useRef, useState } from 'react';
import AppLinks from '../AppLinks/AppLinks';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import WorksBlock from '../WorksBlock/WorksBlock';
import style from './EcosystemBlock.module.scss';

const EcosystemBlock = () => {
  const ref = useRef(null);
  const isBrowser = typeof window !== 'undefined';
  const [isIntersecting, setIntersecting] = useState(false);
  const faunder = [style.faunderContainer];
  const whitepaperBlock = [style.whitepaperBlock];

  // const points: { header: string; description: string | string[] }[] = [
  //   {
  //     header: t('ecosystem.description.item1.header'),
  //     description: t('ecosystem.description.item1.text'),
  //   },
  //   {
  //     header: t('ecosystem.description.item2.header'),
  //     description: [
  //       t('ecosystem.description.item2.text1'),
  //       t('ecosystem.description.item2.text2'),
  //     ],
  //   },
  //   {
  //     header: t('ecosystem.description.item3.header'),
  //     description: t('ecosystem.description.item3.text'),
  //   },
  //   {
  //     header: t('ecosystem.description.item4.header'),
  //     description: t('ecosystem.description.item4.text'),
  //   },
  // ];

  let observer: IntersectionObserver;
  if (isBrowser) {
    observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
  }
  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isIntersecting) {
    faunder.push(style.faunderAminate);
    whitepaperBlock.push(style.whitepaperAnimate);
  }

  return (
    <div className={style.container} ref={ref}>
      <SecondaryLine />
      <div className={style.ecosystem}>
        <div className={style.formBlock}>
          <div className={style.linksBlock}>
            <AppLinks />
          </div>
        </div>
        <WorksBlock />
      </div>
    </div>
  );
};

export default EcosystemBlock;
