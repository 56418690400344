import busd from 'src/assets/images/busd.svg';
import xusd from 'src/assets/images/XUSD-coin.svg';
import usdt from 'src/assets/images/usdt.svg';
import usdc from 'src/assets/images/usdc.svg';
import placed from 'src/assets/images/legal_aspect/privat-placed.svg';
import repository from 'src/assets/images/legal_aspect/repository.svg';
import policy from 'src/assets/images/legal_aspect/privat-policy.svg';

import twitter from 'src/assets/images/footer/twitter-fill.svg';
import facebook from 'src/assets/images/footer/facebook-box-fill.svg';
import instagram from 'src/assets/images/footer/instagram-fill.svg';
import medium from 'src/assets/images/footer/medium-fill.svg';
import telegram from 'src/assets/images/footer/telegram.svg';
import group from 'src/assets/images/footer/x11_group.svg';
import finance from 'src/assets/images/footer/x11_finance.svg';
import bank from 'src/assets/images/footer/x11_bank.svg';

export const IconsFaunder: IconsType[] = [
  { icon: busd, title: 'busd' },
  { icon: xusd, title: 'xusd' },
  { icon: usdt, title: 'usdt' },
  { icon: usdc, title: 'usdc' },
];

export const checkItems: IconsType[] = [
  {
    icon: placed,
    title: 'Memorandum',
    link: 'https://bank11.io/pdf/general-terms',
  },
  {
    icon: repository,
    title: 'Smart Contracts Repository',
    link: 'https://etherscan.io/token/0x12446a71FCedB10AdfD275A1BC78D69Ac3450d2a',
  },
  {
    icon: policy,
    title: 'Privacy Policy',
    link: 'https://bank11.io/pdf/privacy-policy',
  },
];

export type IconsType = {
  icon: string;
  title: string;
  link?: string;
};

export const tableRate: RateType[] = [
  { min: 1, max: 5, rate: 5 },
  { min: 5, max: 15, rate: 8 },
  { min: 15, max: 30, rate: 10 },
  { min: 30, max: 50, rate: 15 },
  { min: 50, max: 70, rate: 20 },
  { min: 0, max: 70, rate: 30 },
];

export type RateType = {
  min: number;
  max: number;
  rate: number;
};

export const socialIcons: IconType[] = [
  { icon: facebook, title: 'facebook' },
  {
    icon: twitter,
    title: 'twitter',
    link: 'https://twitter.com/11Bank11',
  },
  { icon: instagram, title: 'instagram' },
  { icon: medium, title: 'medium', link: 'https://medium.com/@x_11' },
  { icon: telegram, title: 'telegram', link: 'https://t.me/bank11x' },
];

export const financeIcons: IconType[] = [
  { icon: group, title: 'group' },
  { icon: finance, title: 'finance' },
  { icon: bank, title: 'bank' },
];

export type IconType = {
  icon: string;
  title: string;
  link?: string;
};
