import { useTranslation } from 'react-i18next';
import BestSystems from '../BestSystems/BestSystems';
import FaunderBlock from '../FaunderBlock/FaunderBlock';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './PreviewBlock.module.scss';
import PreviewMobile from './PreviewMobile/PreviewMobile';

const PreviewBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={style.previewContainer}>
      <SecondaryLine />
      <div className={style.previewBlock}>
        <div className={style.previewText}>
          <p className={style.secondaryText}>{t('preview.secondaryTitle')}</p>
          <h1 className={style.previewTitle}>{t('preview.title')}</h1>
          <div className={style.descriptions}>
            <p className={style.orange}>{t('preview.description.item1')}</p>
          </div>
          <div className={style.discount}>
            <div className={style.users}>
              {t('preview.discount.users.item1')} <br />
              {t('preview.discount.users.item2')}
            </div>
            <div className={style.percent}>
              <p>0</p>
              <span>%</span>
            </div>
            <div className={style.period}>{t('preview.discount.period')}</div>
          </div>
        </div>
        <FaunderBlock />
      </div>
      <PreviewMobile />
      <BestSystems />
    </div>
  );
};

export default PreviewBlock;
