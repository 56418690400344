import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderBlock from '../HeaderBlock/HeaderBlock';
import SecondaryLine from '../SecondaryLine/SecondaryLine';
import style from './RoadMap.module.scss';

type RoadMapType = {
  secondary?: string;
  quarter: string;
  items: { header?: string; headerSecond?: string; list?: string[] }[];
};

const RoadMap = () => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);
  const roadRef = useRef(null);
  const isBrowser = typeof window !== 'undefined';
  const roadMapStype = [style.roadMap];
  const roadMap: RoadMapType[] = [
    {
      quarter: t('roadMap.point1.quarter'),
      items: [
        {
          list: [
            t('roadMap.point1.item1.list.el1'),
            t('roadMap.point1.item1.list.el2'),
            t('roadMap.point1.item1.list.el3'),
            t('roadMap.point1.item1.list.el4'),
            t('roadMap.point1.item1.list.el5'),
            t('roadMap.point1.item1.list.el6'),
          ],
        },
      ],
    },
    {
      quarter: t('roadMap.point2.quarter'),
      items: [
        {
          list: [
            t('roadMap.point2.item1.list.el1'),
            t('roadMap.point2.item1.list.el2'),
            t('roadMap.point2.item1.list.el3'),
            t('roadMap.point2.item1.list.el4'),
            t('roadMap.point2.item1.list.el5'),
            t('roadMap.point2.item1.list.el6'),
          ],
        },
      ],
    },
    {
      quarter: t('roadMap.point3.quarter'),
      items: [
        {
          list: [
            t('roadMap.point3.item1.list.el1'),
            t('roadMap.point3.item1.list.el2'),
            t('roadMap.point3.item1.list.el3'),
            t('roadMap.point3.item1.list.el4'),
            t('roadMap.point3.item1.list.el5'),
            t('roadMap.point3.item1.list.el6'),
            t('roadMap.point3.item1.list.el7'),
          ],
        },
      ],
    },
    {
      quarter: t('roadMap.point4.quarter'),
      items: [
        {
          list: [
            t('roadMap.point4.item1.list.el1'),
            t('roadMap.point4.item1.list.el2'),
            t('roadMap.point4.item1.list.el3'),
            t('roadMap.point4.item1.list.el4'),
            t('roadMap.point4.item1.list.el5'),
            t('roadMap.point4.item1.list.el6'),
            t('roadMap.point4.item1.list.el7'),
          ],
        },
      ],
    },
  ];

  const onClickPrev = async () => {
    const roadMapNode = document.getElementById('roadMap');
    if (current > 0) {
      setCurrent(current - 2);
      roadMapNode.style.transform = `translateX(0px)`;
      roadMapNode.style.transition = `1s all`;
    }
  };

  const onClickNext = async () => {
    const roadMapNode = document.getElementById('roadMap');
    roadMapNode.style.transition = '1s all';
    if (current + 2 < roadMap.length) {
      setCurrent(current + 2);
      roadMapNode.style.transform = `translateX(-324px)`;
    }
  };

  window?.addEventListener('scroll', () => {
    if (isBrowser) {
      const roadMapBlock = document.getElementById('roadMap-container');
      const roadMapNode = document.getElementById('roadMap');
      const height =
        // eslint-disable-next-line no-unsafe-optional-chaining
        window.innerHeight - roadMapBlock?.getBoundingClientRect().top;
      if (height > 0 && height < roadMapBlock.clientHeight) {
        if (
          roadMapBlock.offsetHeight - height <=
          roadMapBlock.clientHeight / 2
        ) {
          roadMapNode.style.transform = `translateX(-324px)`;
          roadMapNode.style.transition = `15s all`;
        }
      }
    }
  });

  return (
    <div id="roadMap-container" className={style.container} ref={roadRef}>
      <SecondaryLine />
      <HeaderBlock title={t('roadMap.header')} />
      <div className={style.roadMapLine} />
      <div className={style.wrapper}>
        <div className={style.roadMadControl}>
          <div
            className={style.arrowLeft}
            onClick={onClickPrev}
            aria-hidden="true"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.828 12L15.778 16.95L14.364 18.364L8 12L14.364 5.63599L15.778 7.04999L10.828 12Z"
                fill={current === 0 ? '#CACEDB' : '#09121F'}
              />
            </svg>
          </div>
          <div className={style.controlDots}>
            {roadMap.map((el, i) => (
              <div
                key={el.quarter}
                className={i === current ? style.activeDots : ''}
              />
            ))}
          </div>
          <div
            className={style.arrowRight}
            onClick={onClickNext}
            aria-hidden="true"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
            >
              <path
                d="M13.172 12L8.22198 7.04999L9.63598 5.63599L16 12L9.63598 18.364L8.22198 16.95L13.172 12Z"
                fill={current === roadMap.length - 1 ? '#CACEDB' : '#09121F'}
              />
            </svg>
          </div>
        </div>
        <div className={roadMapStype.join(' ')} id="roadMap">
          {roadMap.map((el, i) => (
            <div key={el.quarter} className={style.quarter}>
              <div className={style.quarterPoint}>
                <div className={style.quarterName}>{el.quarter}</div>
                <div className={style.quarterCircle}>
                  <div className={i === current ? style.active : ''} />
                </div>
                {el.items?.map((item, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.header}_${index}`}
                    className={style.quarterItem}
                  >
                    {index > 0 && <br />}
                    <p>{item.header}</p>
                    {item.headerSecond && <p>{item.headerSecond}</p>}
                    {!!item.list?.length && (
                      <ol type="1">
                        {item.list?.map((point) => (
                          <li key={point}>{point}</li>
                        ))}
                      </ol>
                    )}
                  </div>
                ))}
                {el.secondary && <span>{el.secondary}</span>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RoadMap;
