import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lungs from 'src/assets/images/advantages/lungs-fill.svg';
import service from 'src/assets/images/advantages/service-line.svg';
import mouse from 'src/assets/images/advantages/mouse-line.svg';
import barcode from 'src/assets/images/advantages/barcode-line.svg';
import attachment from 'src/assets/images/advantages/attachment-line.svg';
import archive from 'src/assets/images/advantages/archive-line.svg';
import folder from 'src/assets/images/advantages/folder-add-line.svg';
import advertisement from 'src/assets/images/advantages/advertisement.svg';
import microscope from 'src/assets/images/advantages/microscope-fill.svg';
import tube from 'src/assets/images/advantages/test-tube-line.svg';
import database from 'src/assets/images/advantages/database-2-line.svg';
import style from './Advantage.module.scss';
import HeaderBlock from '../HeaderBlock/HeaderBlock';
import SecondaryLine from '../SecondaryLine/SecondaryLine';

const Advantage: FC = () => {
  const { t } = useTranslation();
  const advantages = [
    { icon: lungs, title: t('advantages.item1') },
    { icon: service, title: t('advantages.item2') },
    { icon: mouse, title: t('advantages.item3') },
    { icon: attachment, title: t('advantages.item4') },
    { icon: archive, title: t('advantages.item5') },
    { icon: barcode, title: t('advantages.item6') },
    { icon: folder, title: t('advantages.item7') },
    { icon: advertisement, title: t('advantages.item8') },
    { icon: microscope, title: t('advantages.item10') },
    { icon: tube, title: t('advantages.item11') },
    { icon: database, title: t('advantages.item12') },
    { icon: attachment, title: t('advantages.item13') },
  ];

  const ref = useRef(null);
  const isBrowser = typeof window !== 'undefined';
  const [isIntersecting, setIntersecting] = useState(false);
  const bgText = [style.bgText];
  const item = [style.item];
  let observer: IntersectionObserver;
  if (isBrowser) {
    observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );
  }
  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isIntersecting) {
    bgText.push(style.bgTextAnimate);
    item.push(style.itemAnimate);
  }

  return (
    <div className={style.advantageBlock} ref={ref}>
      <SecondaryLine />
      <div className={bgText.join(' ')}>{t('advantages.freedom')}</div>
      <HeaderBlock title={t('advantages.header')} />
      <div className={style.secondaryTextMobile}>
        Да! <br /> Это не просто рекламный текст
      </div>
      <div className={style.advantages}>
        <div className={style.secondaryText}>
          Да! <br /> Это не просто рекламный текст
        </div>
        {advantages.map((el) => (
          <div key={el.title} className={item.join(' ')}>
            <img src={el.icon} alt={el.title} />
            <p>{el.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Advantage;
