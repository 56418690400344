import './App.css';
import bgImage from 'src/assets/images/bg-image.png';
import Advantage from './components/Advantage/Advantage';
import BenefitBlock from './components/BenefitBlock/BenefitBlock';
import EcosystemBlock from './components/EcosystemBlock/EcosystemBlock';
import Faq from './components/FAQ/Faq';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LegalAspect from './components/LegalAspect/LegalAspect';
import MobileBenefits from './components/MobileBenefits/MobileBenefits';
import PreviewBlock from './components/PreviewBlock/PreviewBlock';
import RoadMap from './components/RoadMap/RoadMap';
import NewsBlock from './components/NewsBlock/NewsBlock';

function App() {
  return (
    <div className="App">
      <img className="bgImage" src={bgImage} alt="bgImage" />
      <div className="container">
        <Header />
        <PreviewBlock />
        <Advantage />
        <LegalAspect />
        <MobileBenefits />
        <BenefitBlock />
        <RoadMap />
        <EcosystemBlock />
        <NewsBlock />
        <Faq />
        <Footer />
      </div>
    </div>
  );
}

export default App;
