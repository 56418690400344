import logo from 'src/assets/images/logo-X11-bank.svg';
import store from 'src/assets/images/app-store-fill.svg';
import google from 'src/assets/images/google-play-fill.svg';
import tocket from 'src/assets/images/x11-tocket-coin.svg';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import style from './AppLinks.module.scss';

const AppLinks = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const pathRu = `${location.pathname}`.includes('/ru');
  const apps = [
    {
      icon: tocket,
      title: t('preview.webLogin'),
      link: `https://app.bank11.io/${pathRu ? 'ru/' : ''}authentication/`,
    },
    { icon: store, title: 'AppStore', link: '' },
    { icon: google, title: 'Play Market', link: '' },
    { icon: google, title: 'APK File', link: '/app-release_1_0_1.apk' },
  ];

  return (
    <div className={style.appsBlock}>
      <img className={style.logo} src={logo} alt="logo" />
      {apps.map(({ icon, title, link }) =>
        link ? (
          <a key={title} className={style.appsItem} href={link}>
            <img src={icon} alt={title} />
            <span>{title}</span>
          </a>
        ) : (
          <div key={title} className={style.appsItem}>
            <img src={icon} alt={title} />
            <span>{title}</span>
          </div>
        ),
      )}
    </div>
  );
};

export default AppLinks;
