import mobile from 'src/assets/images/mobile_1.png';
import AppLinks from 'src/components/AppLinks/AppLinks';
import style from './PreviewMobile.module.scss';

const PreviewMobile = () => {
  return (
    <div className={style.mobile}>
      <img className={style.modileImg} src={mobile} alt="mobile" />
      <div className={style.mobileLinks}>
        <AppLinks />
      </div>
    </div>
  );
};

export default PreviewMobile;
