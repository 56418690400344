import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import Spinner from 'src/components/Spinner/Spinner';
import privacyPolicy from 'src/assets/files/Privacy_Policy_X11.pdf';
import websiteTerms from 'src/assets/files/Website_Terms_X11.pdf';
import tokenPurchase from 'src/assets/files/X11_TOKEN_PURCHASE_AGREEMENT.pdf';
import generalTerms from 'src/assets/files/X11_General_Terms.pdf';
import style from './PDFPage.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const PDFPage = () => {
  const [pages, setPages] = useState([]);
  const { document } = useParams();

  let doc;

  switch (document) {
    case 'privacy-policy':
      doc = privacyPolicy;
      break;
    case 'website-terms':
      doc = websiteTerms;
      break;
    case 'token-purchase':
      doc = tokenPurchase;
      break;
    case 'general-terms':
      doc = generalTerms;
      break;
    default:
      doc = privacyPolicy;
      break;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    const pagesArray = [];
    for (let i = 0; i < numPages; i += 1) {
      pagesArray.push(i + 1);
    }
    setPages(pagesArray);
  };

  console.log('DOC: ', document);

  return (
    <div className={style.iframe}>
      <Document
        loading={<Spinner />}
        file={doc}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pages.map((el) => (
          <Page
            scale={1}
            className={style.page}
            key={el}
            pageNumber={el}
            loading={<div />}
          />
        ))}
      </Document>
    </div>
  );
};

export default PDFPage;
