/* eslint-disable @typescript-eslint/naming-convention */
// import { Link } from 'react-router-dom';
import { PrismicDocument } from '@prismicio/client';
import { FC } from 'react';
import { PrismicRichText } from '@prismicio/react';
// import { convertData } from 'src/helpers/convertDate';
// import { useTranslation } from 'react-i18next';
import cls from './NewsPost.module.scss';

type PropsType = {
  post: PrismicDocument;
};

const NewsPost: FC<PropsType> = ({ post }) => {
  const { uid, data } = post;
  const { image, title, source, sourcelogo } = data;
  return (
    <div className={cls.blog_post_col}>
      <a href={source} className={cls.blog_post_link_more}>
        <div className={cls.blog_post_prewiev_body}>
          <div className={cls.blog_post_prewiev}>
            <img src={image.url} alt={image.alt || uid} />
          </div>
          {/* <div className={cls.blog_post_publish_date}>
            {convertData(datetime)}
          </div> */}
          {/* {source && (
            <div className={cls.blog_post_publish_source}>
              {t('articlePage.source')}: {source}
            </div>
          )} */}
          <div className={cls.blog_post_title}>
            <PrismicRichText field={title} />
          </div>
          {/* <div className={cls.blog_post_description}>
            <PrismicRichText field={resume} />
          </div> */}
        </div>
        <div className={cls.source_logo}>
          <img src={sourcelogo.url} alt={sourcelogo.alt || uid} />
        </div>
      </a>
    </div>
  );
};
export default NewsPost;
