import { useTranslation } from 'react-i18next';
import mobile from 'src/assets/images/mobile_1.png';

import SecondaryLine from '../SecondaryLine/SecondaryLine';
import Whitepaper from '../Whitepaper/Whitepaper';
import style from './MobileBenefits.module.scss';

const MobileBenefits = () => {
  const { t } = useTranslation();
  const benefits = [
    t('mobileBenefits.item1'),
    t('mobileBenefits.item2'),
    t('mobileBenefits.item3'),
    t('mobileBenefits.item4'),
    t('mobileBenefits.item5'),
    t('mobileBenefits.item6'),
    t('mobileBenefits.item7'),
  ];

  return (
    <div className={style.container}>
      <SecondaryLine />
      <div className={style.mobileBenefits}>
        <img className={style.benefitsImg} src={mobile} alt="mobile-benefits" />
        <div className={style.benefits}>
          {benefits.map((item) => (
            <div key={item} className={style.benefits__item}>
              <p>{item}</p>
            </div>
          ))}
          <div className={style.whitepaperBlock}>
            <Whitepaper />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBenefits;
