import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import style from './BestSystems.module.scss';

const BestSystems = () => {
  const { t } = useTranslation();

  const systems = useRef(null);
  const bgTextSystems = useRef(null);

  window.addEventListener('scroll', () => {
    if (!systems.current) return;
    const systemBlock = document.getElementById('bestSystemsBlock');
    const height =
      // eslint-disable-next-line no-unsafe-optional-chaining
      window.innerHeight - systemBlock?.getBoundingClientRect().top;
    if (height > 0 && height < systemBlock.clientHeight) {
      systems.current.style.transform = `translateY(${-height / 8 + 20}px)`;
      bgTextSystems.current.style.top = `${
        (height / systemBlock.clientHeight) * 100
      }px`;
    }
  });

  return (
    <div className={style.container}>
      <div id="bestSystemsBlock" className={style.bestSystemsBlock}>
        <div className={style.bgText}>{t('preview.bestSystem.bgText')}</div>
        <div className={style.systems} ref={systems}>
          <div
            id="bgTextSystems"
            className={style.bgTextSystems}
            ref={bgTextSystems}
          >
            {t('preview.bestSystem.bgText')}
          </div>
          <div className={style.points}>
            <div className={style.circle} />
            <div className={style.line} />
            <div className={style.circle} />
          </div>
          <div className={style.systemItem}>
            <div className={style.systemHeader}>
              {t('preview.bestSystem.classic.title')}
            </div>
            <p className={style.systemDescription}>
              {t('preview.bestSystem.classic.description')}
            </p>
          </div>
          <div className={style.systemItem}>
            <div className={style.systemHeader}>
              {t('preview.bestSystem.crypto.title')}
            </div>
            <p className={style.systemDescription}>
              {t('preview.bestSystem.crypto.description')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BestSystems;
