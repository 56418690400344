import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import whitepaper_ru from 'src/assets/files/whitepaper_ru.pdf';
import whitepaper_en from 'src/assets/files/whitepaper_en_new.pdf';
import Spinner from 'src/components/Spinner/Spinner';
import style from './WhitepaperPage.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const WhitepaperPage = () => {
  const { i18n } = useTranslation();
  const [pages, setPages] = useState([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    const pagesArray = [];
    for (let i = 0; i < numPages; i += 1) {
      pagesArray.push(i + 1);
    }
    setPages(pagesArray);
  };

  return (
    <div className={style.iframe}>
      <Document
        loading={<Spinner />}
        file={i18n.language === 'ru' ? whitepaper_ru : whitepaper_en}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pages.map((el) => (
          <Page
            scale={1}
            className={style.page}
            key={el}
            pageNumber={el}
            loading={<div />}
          />
        ))}
      </Document>
    </div>
  );
};

export default WhitepaperPage;
